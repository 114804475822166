
.header__container-light{
    outline: none;
    border-color: #88bde9;
    box-shadow: 0 0 15px #6db0e7;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px; 
}
.header__container{
    outline: none;
    border-color: #7af77a;
    box-shadow: 0 0 15px #4ff701;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.header__glow{
    background-color: #000000;
    top: 'auto';
    bottom: 0;
}
.header__glow-light{
    background-color: white;
    top: 'auto';
    bottom: 0;
    color: #c7c7c7
}

.header__menu{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 2px;
    width: 150px;
    height: 100px;
    border-radius: 25px;
}

.menu-light{
    background-color: white;
    color: #000000;
    border: 15px solid white;

}
.menu-dark{
    background-color: #000000;
    color: white;
    border: 15px solid #000000;


}
.link-dark{
    text-decoration: none;
    color: white;
} 
.link-light{
    text-decoration: none;
    color: #000000;
}

.avatar-link{
    -webkit-tap-highlight-color: transparent;
}

.landing__lang-btns{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    animation-duration: 3s;
    animation-name: slideinFromBottom;
}

.landing__btn{
    cursor: pointer;
}

.MuiPopover-paper{
    background-color: transparent !important;
    background-image: none !important;
    padding: 1rem;
}

@keyframes slideinFromBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.icon-animation {
    transition: transform 0.3s ease-in-out;
}
  
/* When the icon changes, rotate it */
.isOpen .icon-animation {
    transform: rotate(180deg);
}