body {
  margin: 0;
  font-family: 'Ysabeau';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  width: 0;
}

code {
  font-family: 'Ysabeau';
}