/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

.ejemplo {
    height: 20px;
    width: 5px;
}

.modal_box {
    max-width: 80%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.5);
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
}

.box_dark {
    background-color: #000000;
    color: white;
    border-radius: 2px;
    border-color: gray;
    border-style: solid;
}

.box_light {
    background-color: white;
    color: #000000;
    border-radius: 2px;
    border-color: gray;
    border-style: solid;
}

.job_title {
  color: rgb(1, 173, 1);
}

@keyframes shake {
    0%, 20% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(-1.5deg);
    }
    40% {
      transform: rotate(1.5deg);
    }
    50% {
      transform: rotate(-1.5deg);
    }
    60%, 100% {
      transform: rotate(0deg);
    }
  }
  