.login-main-layout{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
}

.dark-mode {
    background-color: white;
}

.login-btn {
    background-color: black;
    color: white;
}

.login-btn_dark-mode{
    background-color: white;
    color: black;
}