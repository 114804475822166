.register-main-layout{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
}

.dark-mode {
    background-color: white;
}

.register-btn {
    background-color: black;
    color: white;
}

.register-btn_dark-mode{
    background-color: white;
    color: black;
}

.register_modal_box {
    max-width: 80%;
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.5);
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
}

.register_box_dark {
    background-color: #000000;
    color: white;
    border-radius: 2px;
    border-color: gray;
    border-style: solid;
}

.register_box_light {
    background-color: white;
    color: #000000;
    border-radius: 2px;
    border-color: gray;
    border-style: solid;
}