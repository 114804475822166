.home_main {
    padding-top: 10px;
    padding-bottom: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
}

.upper_side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100vw;
    min-height: 40vh;
    /* background-image: url('../../assets/teletrabajo-fondo.webp'); */
}

.home_main-dark {
    background-color: #000000;
    color: white;
}

.home_main-light {
    background-color: white;
    color: #000000;
}

@keyframes vibrate {
  0% { transform: translate(0); }
  25% { transform: translate(-2px, -2px); }
  50% { transform: translate(5px, 5px); }
  75% { transform: translate(-2px, 2px); }
  100% { transform: translate(0); }
}

.call-to-action {
  animation: vibrate 0.5s infinite;
  animation-delay: 2s; /* starts the animation after 2 seconds */
}

.job_posts_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    width: 100vw;
    min-height: 100vh;
}