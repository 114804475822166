.search_bar .MuiOutlinedInput-root {
    border-radius: 30px;
    margin-bottom: 30px;
    /* animation: vibrate 2.5s infinite; */
}

/* .search_bar .MuiOutlinedInput-root:focus,
.search_bar .MuiOutlinedInput-root:focus-within {
    animation: none;
}

@keyframes vibrate {
    0% { transform: rotate(0deg); }
    80% { transform: rotate(0deg); }
    85% { transform: rotate(1deg); }
    95% { transform: rotate(-1deg); }
    100% { transform: rotate(0deg); }
} */
